import {useSelector} from "react-redux";
import {ReduxState} from "../redux/reducers";
import {ReduxAuthState} from "../redux/reducers/auth";


function useTeamleaderTokens() {
    const state: ReduxAuthState = useSelector<ReduxState, ReduxAuthState>((app: ReduxState) => app.auth);

    return {
        ...state?.user?.data || {accessToken: '', refreshToken: ''},
        authenticated: !!state.user?.data?.accessToken || false
    };
}


export default useTeamleaderTokens;
