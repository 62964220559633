import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { authenticateUser } from "../redux/actions/Actions";
import { loginUser } from "../lib/serverCommunication";
import logo from "../assets/images/logo.png";
import { useHistory } from "react-router";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const onChangeHandler = (fn) => (e) => {
    if (error) {
      setError("");
    }
    fn(e.target.value);
  };

  const handleLogin = (event) => {
    if (event) {
      event.preventDefault();
    }
    setLoading(true);
    setError("");
    loginUser({ email, password })
      .then((res) => {
        if (res.error) {
          return setError(res.error);
        }
        if (res.user.roles.includes("admin")) {
          dispatch(authenticateUser(res));
          return history.replace("/");
        } else {
          return setError("You do not have permission to access this panel");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleLogin}>
      <div className="LoginView">
        <div className="card">
          <img src={logo} alt="Creadomotics" />
          <h2>Login</h2>
          {!!error && <p className="error">{error}</p>}
          <div className="login-form">
            <input
              type="email"
              name="email"
              value={email}
              onChange={onChangeHandler(setEmail)}
              placeholder="Email"
            />
          </div>
          <div className="login-form">
            <input
              type="password"
              name="password"
              value={password}
              onChange={onChangeHandler(setPassword)}
              placeholder="Wachtwoord"
            />
          </div>
          <Button
            type="submit"
            className="login-btn"
            onClick={handleLogin}
            loading={loading}
          >
            Login
          </Button>
        </div>
      </div>
    </form>
  );
}

export default Login;
