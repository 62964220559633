import {BaseRow, Importer, ImporterField} from "react-csv-importer";
import {patchManyCustomers} from "../../lib/serverCommunication";
import React from "react";
import {useDispatch} from "react-redux";
import {addQuotation, setQuotations} from "../../redux/actions/Actions";
import Quotation from "../../lib/Quotation";

function ImportCsv() {
    const dispatcher = useDispatch();

    return (
        <Importer
            // chunkSize={20000} // intentionally small chunk size for interactive display
            restartable
            onStart={({file}) => {
                dispatcher(setQuotations([]));
            }}
            processChunk={async (rows: BaseRow[]) => {
                let buffer: any[] = [];
                for (let i = 0; i < rows.length; i++) {
                    // @ts-ignore
                    const quotation = new Quotation(rows[i]);
                    dispatcher(addQuotation(quotation));

                    buffer.push(rows[i]);

                    if (buffer.length === 50) {
                        await patchManyCustomers(buffer);
                        buffer = [];
                    }
                }

            }}
        >
            <ImporterField name="companyName" label="Bedrijfsentiteit" optional/>
            <ImporterField name="companyId" label="Nummer" optional/>
            <ImporterField name="contact" label="Contactpersoon" optional/>
            <ImporterField name="customer" label="Klant" optional/>
            <ImporterField name="title" label="Titel" optional/>
            <ImporterField name="itemCount" label="Aantal items" optional/>
            <ImporterField name="taxTariffItem" label="Btw-tarief item" optional/>
            <ImporterField name="totalExclVat" label="Item: totaal excl. btw" optional/>
            <ImporterField name="totalInclVat" label="Item: totaal incl. btw" optional/>
            <ImporterField name="itemName" label="Naam item" optional/>
            <ImporterField name="itemExclVat" label="Prijs item excl. btw" optional/>
            <ImporterField name="category" label="Tussentitel Item" optional/>
            <ImporterField name="description" label="Omschrijving" optional/>

        </Importer>
    )
}

export default ImportCsv;
