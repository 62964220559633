import React, { useCallback, useState } from "react";
import Title from "antd/lib/typography/Title";
import QuotationTable from "../components/QuotationTable/QuotationTable";
import useQuotation from "../hooks/useQuotation";
import { teamleaderImportQuotation } from "../lib/serverCommunication";
import { message } from "antd";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { importedCurrentQuotation } from "../redux/actions/Actions";

function ImportQuotationPage() {
  const { groupedLines } = useQuotation();
  const [dealId, updateDealId] = useState<string>();
  const [importing, updateImporting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const importQuotations = useCallback(() => {
    updateImporting(true);
    const hide = message.loading("Offerte importeren...", 0);
    dispatch(importedCurrentQuotation(true));
    teamleaderImportQuotation(
      groupedLines[0].quotations[0].title,
      groupedLines
    ).then((body) => {
      hide();
      updateImporting(false);
      if (body.teamleader.success) {
        message.success("Importeren gelukt");
        updateDealId(body.teamleader.dealId);
      } else {
        message.error(
          "Er ging iets fout. Controleer de gegevens en probeer het later nog eens."
        );
      }
    });
  }, [dispatch, groupedLines]);

  const openDealInTeamleader = useCallback(() => {
    const win = window.open(
      `https://app.teamleader.eu/web/deals/${dealId}`,
      "_blank"
    );
    win!.focus();
  }, [dealId]);

  return (
    <>
      <div className="flex flex-row justify-between items-center pb-4">
        <Title>Importeer Offerte</Title>
        {!dealId ? (
          <button
            disabled={importing}
            className={classNames(
              "bg-green-500 text-white px-3 py-2 rounded-md",
              { "disabled:opacity-50": importing }
            )}
            onClick={importQuotations}
          >
            Offerte importeren
          </button>
        ) : (
          <>
            <button
              disabled={importing}
              className="bg-blue-500 text-white px-3 py-2 rounded-md"
              onClick={openDealInTeamleader}
            >
              Deal openen
            </button>
          </>
        )}
      </div>
      <div className="flex flex-col">
        <QuotationTable />
      </div>
    </>
  );
}

export default ImportQuotationPage;
