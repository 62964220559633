import React, {useCallback} from "react";
import {teamleaderLogin} from "../lib/serverCommunication";

function TeamleaderLogin() {
    const login = useCallback(() => {
        teamleaderLogin()
            .then((body) => {
                const win = window.open(body.teamleader.redirectUrl, '_blank');
                win!.focus();
            })
    }, []);

    return (
        <>
            <h1>Login bij Teamleader om verder te gaan.</h1>

            <button
                className="bg-blue-500 text-white px-3 py-2 rounded-md"
                onClick={login}
            >
                Inloggen bij Teamleader
            </button>
        </>
    )
}

export default TeamleaderLogin;
