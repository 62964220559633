import React, { useCallback, useState } from "react";
import { Layout } from "antd";
import SidebarMenu from "./SidebarMenu";
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import "../scss/header.scss";
import { logoutUser } from "../redux/actions/Actions";
import { useDispatch } from "react-redux";

const { Header, Content } = Layout;

interface DefaultLayoutProps {
  verifying?: boolean;
  children: JSX.Element | JSX.Element[];
}

function DefaultLayout({ verifying = false, children }: DefaultLayoutProps) {
  const [collapsedState, updateCollapsedState] = useState<boolean>(true);
  const toggleCollapsed = useCallback(
    () => updateCollapsedState(!collapsedState),
    [collapsedState]
  );
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(logoutUser()), [dispatch]);

  return (
    <Layout>
      <SidebarMenu
        collapsed={collapsedState}
        toggleCollapse={toggleCollapsed}
        verifying={verifying}
      />
      <Layout style={{ marginLeft: collapsedState ? 80 : 200 }}>
        <Header style={{ padding: 0 }}>
          <div className="flex flex-row items-center justify-between">
            <div className="flex">
              {collapsedState ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  onClick={toggleCollapsed}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  onClick={toggleCollapsed}
                />
              )}
            </div>
            <div className="flex justify-end">
              <LogoutOutlined className="trigger" onClick={logout} />
            </div>
          </div>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
}

export default DefaultLayout;
