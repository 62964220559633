import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useUrlQuery from "../hooks/useUrlQuery";
import { teamleaderCallback } from "../lib/serverCommunication";
import { authenticateUser } from "../redux/actions/Actions";
import { useHistory } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";

function TeamleaderCallback() {
  const dispatch = useDispatch();
  const history = useHistory();

  const codeArr = useUrlQuery("code");
  const code = codeArr[0];

  useEffect(() => {
    if (!code) return;
    teamleaderCallback(code).then((res) => {
      dispatch(authenticateUser(res.teamleader));
      history.replace("/");
    });
  }, [code, history, dispatch]);

  return <LoadingOutlined className="text-7xl" />;
}

export default TeamleaderCallback;
