import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import { DashboardOutlined, FileExcelOutlined } from "@ant-design/icons";
import Logo from "../assets/images/notores-logo";
import useTeamleaderTokens from "../hooks/useTeamleaderTokens";
import { MenuInfo } from "rc-menu/lib/interface";

const { Sider } = Layout;

interface MenuItemsProps {
  all: boolean;
  currentItem: string[];
  onMenuItemClick: (data: MenuInfo) => void;
}

const MenuItems = ({ all, currentItem, onMenuItemClick }: MenuItemsProps) => {
  const { authenticated } = useTeamleaderTokens();

  if (all) {
    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={currentItem}
        onClick={onMenuItemClick}
      >
        <Menu.Item key="/">
          <DashboardOutlined />
          <span>Home</span>
          <Link to={"/"} />
        </Menu.Item>
        {authenticated && (
          <Menu.Item key="/import/csv">
            <FileExcelOutlined />
            <span>Offerte importeren</span>
            <Link to={"/import/csv"} />
          </Menu.Item>
        )}
      </Menu>
    );
  }

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={currentItem}
      onClick={onMenuItemClick}
    >
      <Menu.Item key="/">
        <DashboardOutlined />
        <span>Home</span>
        <Link to={"/"} />
      </Menu.Item>
    </Menu>
  );
};

interface SidebarMenuProps {
  collapsed: boolean;
  verifying: boolean;
  toggleCollapse: () => void;
}

const SidebarMenu = ({
  collapsed,
  verifying,
  toggleCollapse,
}: SidebarMenuProps) => {
  let [currentItem, updateCurrentItem] = useState<string>(
    useLocation().pathname || "/"
  );

  const onMenuItemClick = ({ item, key, keyPath }: MenuInfo) => {
    updateCurrentItem(key as string);
  };

  if (currentItem.indexOf("/orders/process") > -1) {
    currentItem = "/";
  } else if (currentItem.startsWith("/products/")) {
    currentItem = "/products";
  }

  return (
    <Fragment>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <div className="flex row justify-content-center logo">
          <Logo />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[currentItem]}
          onClick={onMenuItemClick}
        >
          <MenuItems
            all={!verifying}
            currentItem={[currentItem]}
            onMenuItemClick={onMenuItemClick}
          />
        </Menu>
      </Sider>
    </Fragment>
  );
};

export default SidebarMenu;
