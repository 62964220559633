import {AUTHENTICATE, LOGOUT} from "../actions/Actions";
import {UserInterface} from "../../types/User.interface";

export type ReduxUser = UserInterface & Record<'accountType', string>

export interface ReduxAuthState {
    accountActivated: boolean,
    jwt: string,
    user: ReduxUser
}

const initialState: ReduxAuthState = {
    accountActivated: false,
    jwt: '',
    user: {
        email: '',
        firstname: '',
        lastname: '',
        accountType: '',
        roles: [],
        data: {
            accessToken: '',
            refreshToken: '',
        }
    },
};

const auth = (state: ReduxAuthState = {...initialState}, action: { type: string, payload: {user: ReduxUser, jwt: string }}): ReduxAuthState => {
    switch (action.type) {
        case AUTHENTICATE:
            return {
                ...state,
                accountActivated: true,
                user: action.payload.user,
                jwt: action.payload.jwt,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export default auth;


