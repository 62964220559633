class Quotation {

    category: string;
    companyName: string;
    contact: string;
    customer: string;
    description: string;
    itemCount: number;
    itemName: string;
    companyId: number;
    taxTariffItem: string;
    title: string;
    itemExclVat: number;
    totalExclVat: number;
    totalInclVat: number;

    constructor(
        {
            category,
            companyName,
            contact,
            customer,
            description,
            itemCount,
            itemExclVat,
            itemName,
            companyId,
            taxTariffItem,
            title,
            totalExclVat,
            totalInclVat,
        }: Quotation & {
            itemCount: string;
            itemExclVat: string;
            totalExclVat: string;
            totalInclVat: string;
        }
    ) {
        this.category = category;
        this.companyName = companyName;
        this.contact = contact;
        this.customer = customer;
        this.description = description;
        this.itemCount = parseInt(itemCount);
        this.itemExclVat = parseInt(itemExclVat);
        this.itemName = itemName;
        this.companyId = companyId;
        this.taxTariffItem = taxTariffItem;
        this.title = title;
        this.totalExclVat = parseInt(totalExclVat);
        this.totalInclVat = parseInt(totalInclVat);
    }
}

export default Quotation;
