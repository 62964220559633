import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./App.scss";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import ImportCsvPage from "./pages/ImportCsvPage";
import { persistor, store } from "./redux/configureStore";
import MainPage from "./pages/MainPage";
import Login from "./pages/Login";
import { logoutUser } from "./redux/actions/Actions";
import { getProfile } from "./lib/serverCommunication";
import { ReduxState } from "./redux/reducers";
import { ReduxAuthState } from "./redux/reducers/auth";
import { UserInterface } from "./types/User.interface";
import TeamleaderCallback from "./pages/TeamleaderCallback";
import useTeamleaderTokens from "./hooks/useTeamleaderTokens";
import TeamleaderLogin from "./pages/TeamleaderLogin";
import ImportQuotationPage from "./pages/ImportQuotationPage";
import { LoadingOutlined } from "@ant-design/icons";

const AuthRoutes = function AuthRoutes() {
  const [verifyingAuth, updateVerifyingAuth] = useState(true);
  const auth: ReduxAuthState = useSelector<ReduxState, ReduxAuthState>(
    (state: ReduxState) => state.auth
  );
  const dispatch = useDispatch();
  const location = useLocation();

  const { authenticated } = useTeamleaderTokens();

  useEffect(() => {
    if (!auth.jwt) {
      return;
    }
    getProfile().then((body: Record<"user", UserInterface>) => {
      if (
        body.user &&
        body.user.roles &&
        Array.isArray(body.user.roles) &&
        body.user.roles.includes("admin")
      ) {
        return updateVerifyingAuth(false);
      } else {
        return dispatch(logoutUser());
      }
    });
  });

  if (!auth.jwt) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  if (verifyingAuth) {
    return (
      <DefaultLayout verifying>
        <div className="flex justify-center items-center w-full h-full">
          <LoadingOutlined className="text-7xl" />
        </div>
      </DefaultLayout>
    );
  }

  if (!authenticated) {
    return (
      <Router>
        <DefaultLayout>
          <Route exact path="/">
            <TeamleaderLogin />
          </Route>
          <Route exact path="/teamleader/auth/callback">
            <TeamleaderCallback />
          </Route>
        </DefaultLayout>
      </Router>
    );
  }

  return (
    <Router>
      <DefaultLayout>
        <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route exact path="/import/csv">
            <ImportCsvPage />
          </Route>
          <Route exact path="/import/quotation">
            <ImportQuotationPage />
          </Route>
        </Switch>
      </DefaultLayout>
    </Router>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={<LoadingOutlined className="text-7xl" />}
        persistor={persistor}
      >
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <AuthRoutes />
            </Route>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
