import React, { useEffect, useState } from "react";
import { teamleaderGetProfile } from "../lib/serverCommunication";
import { LoadingOutlined } from "@ant-design/icons";

interface ProfileData {
  first_name?: string;
  last_name?: string;
}

function MainPage() {
  const [profile, updateProfile] = useState<ProfileData>({});

  useEffect(() => {
    teamleaderGetProfile().then((body) => {
      updateProfile(body.teamleader.data);
    });
  }, []);

  if (!profile.first_name) {
    return (
      <>
        <LoadingOutlined className="text-7xl" />
      </>
    );
  }

  return (
    <>
      <h1 className="text-3xl">
        Hallo {profile.first_name} {profile.last_name}!
      </h1>
    </>
  );
}

export default MainPage;
