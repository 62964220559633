/*
 * action types
 */
import {ReduxUser} from "../reducers/auth";
import Quotation from "../../lib/Quotation";

export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';

// QUOTATIONS
export const SET_QUOTATIONS = 'SET_QUOTATIONS';
export const ADD_QUOTATIONS = 'ADD_QUOTATIONS';
export const IMPORTED_CURRENT_QUOTATION = 'NEW_IMPORT';
/*
 * action creators
 */
export function authenticateUser(user: ReduxUser) {
  return { type: AUTHENTICATE, payload: user };
}

export function logoutUser() {
  return { type: LOGOUT };
}

export function setQuotations(quotations: Quotation[]) {
  return { type: SET_QUOTATIONS, payload: quotations };
}

export function addQuotation(quotation: Quotation) {
  return { type: ADD_QUOTATIONS, payload: quotation};
}

export function importedCurrentQuotation(newImportBool: boolean) {
  return { type: IMPORTED_CURRENT_QUOTATION, payload: newImportBool }
}
