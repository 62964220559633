import {ReduxQuotationState} from "../redux/reducers/quotation";
import {useSelector} from "react-redux";
import {ReduxState} from "../redux/reducers";
import {useMemo} from "react";
import QuotationCategory from "../lib/QuotationCategory";
import Quotation from "../lib/Quotation";

function useQuotation() {
    const {quotations}: ReduxQuotationState = useSelector<ReduxState, ReduxQuotationState>((state: ReduxState) => state.quotation);

    const lines = useMemo(() => {
        const data: QuotationCategory[] = [];
        let quotationCategory: QuotationCategory;
        quotations.forEach((quotation: Quotation, index: number) => {
            if (!quotationCategory || (quotation.category && quotation.category !== quotationCategory.category)) {
                quotationCategory = new QuotationCategory(quotation.category, `${quotation.category}-${index}`);
                data.push(quotationCategory);
            } else {
                quotation.category = quotationCategory.category;
            }
            quotationCategory.add(quotation);
        });
        return data;
    }, [quotations]);

    return  {
        groupedLines: lines
    }

}

export default useQuotation
