import React, { Fragment, useCallback, useEffect } from "react";
import { Typography } from "antd";
import "react-csv-importer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import ImportCsv from "./ImportInvoicePageComponents/ImportCsv";
import {
  importedCurrentQuotation,
  setQuotations,
} from "../redux/actions/Actions";
import useQuotation from "../hooks/useQuotation";
import QuotationTable from "../components/QuotationTable/QuotationTable";
import { useHistory } from "react-router";
import { ReduxQuotationState } from "../redux/reducers/quotation";
import { ReduxState } from "../redux/reducers";

const { Title } = Typography;

function ImportCsvPage() {
  const dispatch = useDispatch();
  const { hasImportedCurrentQuotation }: ReduxQuotationState = useSelector<
    ReduxState,
    ReduxQuotationState
  >((state: ReduxState) => state.quotation);
  useEffect(() => {
    if (hasImportedCurrentQuotation) {
      dispatch(importedCurrentQuotation(false));
      dispatch(setQuotations([]));
    }
  }, [dispatch, hasImportedCurrentQuotation]);

  const { groupedLines } = useQuotation();
  const history = useHistory();
  const reset = useCallback(() => {
    if (window.confirm("Data resetten?")) {
      dispatch(setQuotations([]));
    }
  }, [dispatch]);

  const toImportQuotation = useCallback(() => {
    if (groupedLines.length === 0) {
      return;
    }
    history.push("/import/quotation");
  }, [groupedLines, history]);

  return (
    <Fragment>
      <div className="flex flex-row justify-between items-center pb-4">
        <Title>Importeer CSV</Title>
        {groupedLines.length > 0 && (
          <button
            className="bg-blue-500 text-white px-3 py-2 rounded-md"
            onClick={reset}
          >
            Reset
          </button>
        )}
      </div>

      <div>
        {groupedLines.length === 0 && <ImportCsv />}
        {groupedLines.length > 0 && (
          <>
            <QuotationTable />
            <div className="flex flex-row justify-end pt-4">
              <button
                onClick={toImportQuotation}
                className="bg-green-500 text-white px-3 py-2 rounded-md"
              >
                Volgende
              </button>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
}

export default ImportCsvPage;
