import {Table} from "antd";
import React from "react";
import useQuotation from "../../hooks/useQuotation";
import QuotationCategory from "../../lib/QuotationCategory";
import Quotation from "../../lib/Quotation";
import {formatCurrency} from "../../lib/formatter";

const columns = [
    {dataIndex: 'category', title: 'Categorie'},
    {
        dataIndex: 'itemCount',
        title: 'Aantal items',
        render: (_: any, record: QuotationCategory) => record.quotations.reduce<number>((previousValue: number, currentValue: Quotation) => previousValue += currentValue.itemCount, 0)
    },
    {
        dataIndex: 'totalExclVat',
        title: 'Item: totaal excl. btw',
        render: (_: any, record: QuotationCategory) =>
            formatCurrency(
                record.quotations.reduce<number>((previousValue: number, currentValue: Quotation) => previousValue += currentValue.itemExclVat, 0)
            )
    },
    {
        dataIndex: 'totalInclVat',
        title: 'Item: totaal incl. btw',
        render: (_: any, record: QuotationCategory) =>
            formatCurrency(
                record.quotations.reduce<number>((previousValue: number, currentValue: Quotation) => previousValue += currentValue.totalInclVat, 0)
            )
    },
]

const childColumns = [
    {dataIndex: 'itemName', title: 'Naam item'},
    {dataIndex: 'itemCount', title: 'Aantal items'},
    {dataIndex: 'itemExclVat', title: 'Prijs item excl. btw', render: (text: number) => formatCurrency(text)},
    {dataIndex: 'totalExclVat', title: 'Item: totaal excl. btw', render: (text: number) => formatCurrency(text)},
    {dataIndex: 'totalInclVat', title: 'Item: totaal incl. btw', render: (text: number) => formatCurrency(text)},
    {dataIndex: 'description', title: 'Omschrijving'},
]


function renderRow(record: QuotationCategory): JSX.Element {
    return (<Table columns={childColumns} dataSource={record.quotations} pagination={false}/>);
}


function QuotationTable() {
    const {groupedLines} = useQuotation();

    return (
        <Table dataSource={groupedLines} columns={columns} pagination={false} expandable={{expandedRowRender: renderRow}}/>
    )
}

export default QuotationTable
