import { combineReducers } from "redux";

import auth, {ReduxAuthState} from './auth';
import quotation, {ReduxQuotationState} from './quotation';

export interface ReduxState {
  auth: ReduxAuthState;
  quotation: ReduxQuotationState;
}

export default combineReducers({
  auth,
  quotation: quotation
})
