import { store } from "./../redux/configureStore";
import { authenticateUser } from "../redux/actions/Actions";

const serverConfig = Object.freeze({
  serverUrl: "https://creadomotics-server.appserver1.silicon.be",
  //serverUrl: 'http://localhost:5007'
});

const genFetch = async (url, options, query) => {
  let address;
  if (typeof url === "string") {
    address = serverConfig.serverUrl + url;
  } else if (typeof url === "object") {
    if (url.url.startsWith("http")) {
      address = url.url;
    } else {
      if (url.admin) {
        //
        address = `${serverConfig.serverUrl}/n-admin${url.url}`;
      } else {
        address = `${serverConfig.serverUrl}${url.url}`;
      }
    }
  }

  if (query) {
    address += "?";
    for (const k in query) {
      console.log(k);
      address += Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join("&");
    }
  }

  return fetch(address, options).then((res) => {
    return res.json();
  });
};

const genTeamleaderFetch = (url, options, query) => {
  return new Promise(async (resolve, reject) => {
    const result = await genFetch(url, options, query);
    if (result.teamleader.errors) {
      const refreshResult = await genFetch(
        "/teamleader/auth/refreshtoken",
        genOptions()
      );
      store.dispatch(authenticateUser(refreshResult.teamleader));
    } else {
      return resolve(result);
    }

    resolve(await genFetch(url, options, query));
  });
};

const genOptions = (method = "GET", body, auth = true) => {
  const options = {
    method: method.toUpperCase(),
    credentials: "omit",
    cache: "no-cache",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  if (auth) {
    const state = store.getState();
    const auth = state.auth;
    if (auth.jwt) {
      options.headers.Authorization = `Bearer ${auth.jwt}`;
    }
  }

  if (!["GET", "DELETE"].includes(method)) {
    options.body = JSON.stringify(body);
  }

  return options;
};

export const getProfile = () => genFetch("/profile", genOptions());

export const registerUser = (userInfo) => {
  const body = {
    ...userInfo,
  };

  return genFetch(`/register`, genOptions("post", body, false));
};

export const loginUser = ({ email, password }) => {
  const body = {
    email,
    password,
  };

  return genFetch("/login", genOptions("post", body, false));
};

/*******************************
 ********** TEAMLEADER *********
 *******************************/
export const teamleaderLogin = () =>
  genFetch("/teamleader/auth/login", genOptions());
export const teamleaderGetProfile = () =>
  genTeamleaderFetch("/teamleader/auth/me", genOptions());
export const teamleaderCallback = (code) =>
  genTeamleaderFetch(`/teamleader/auth/callback?code=${code}`, genOptions());

export const teamleaderImportQuotation = (name, groupedLines) =>
  genTeamleaderFetch(
    "/teamleader/deal/import",
    genOptions("POST", {
      name,
      groupedLines,
    })
  );

/*******************************
 *********** PRODUCTS **********
 *******************************/

export const patchManyCustomers = (body) =>
  genFetch({ url: "/customers", admin: false }, genOptions("PATCH", body));
