import Quotation from "./Quotation";

class QuotationCategory {

    public category: string;
    public key: string;
    public quotations: Quotation[] = [];

    constructor(category: string, key: string) {
        this.category = category;
        this.key = key;
    }

    add(quotation: Quotation) {
        this.quotations.push(quotation);
    }

}

export default QuotationCategory;
