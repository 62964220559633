import {
  ADD_QUOTATIONS,
  IMPORTED_CURRENT_QUOTATION,
  SET_QUOTATIONS,
} from "../actions/Actions";
import Quotation from "../../lib/Quotation";

interface Columns {
  title: string;
  key: string;
}

export interface ReduxQuotationState {
  quotations: Quotation[];
  categories: string[];
  columns: Columns[];
  selectedDeal: string;
  hasImportedCurrentQuotation: boolean;
}

const initialState: ReduxQuotationState = {
  quotations: [],
  categories: [],
  columns: [],
  selectedDeal: "",
  hasImportedCurrentQuotation: false,
};

const quotation = (
  state: ReduxQuotationState = { ...initialState },
  action: {
    type: string;
    payload: ReduxQuotationState | Quotation | string | boolean;
  }
) => {
  const newState = { ...state };
  switch (action.type) {
    case SET_QUOTATIONS:
      return {
        ...state,
        quotations: action.payload,
      };

    case ADD_QUOTATIONS:
      if (action.payload instanceof Quotation) {
        const newQuotations: Quotation[] = [...newState.quotations];
        newQuotations.push(action.payload);
        newState.quotations = newQuotations;
      }

      return newState;

    case IMPORTED_CURRENT_QUOTATION:
      newState.hasImportedCurrentQuotation = action.payload as boolean;
      return newState;

    default:
      return state;
  }
};

export default quotation;
