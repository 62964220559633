import { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';

function getValues(state: Record<string, string>, key: string) {
    const data = state[key];
    if (!data) {
        return [];
    }
    return Array.isArray(data) ? data : [data];
}

function useUrlQuery(key: string) {
    const [queryParams, updateQueryParams] = useState([]);

    const getParams = useCallback((state, prevState) => {
        if (!state) {
            return prevState;
        }

        const values = [key, `${key}[]`].map((k: string) => getValues(state, k)).flat();

        const isDifferent = (prevState.length !== values.length)
            || ([...prevState].sort().toString() !== [...values].sort().toString());

        return isDifferent ? values : prevState;
    }, [key]);

    const updateParams = useCallback((state) => {
        const params = getParams(state, queryParams);
        if (params !== queryParams) {
            updateQueryParams(params);
        }
    }, [getParams, queryParams]);

    useEffect(() => {
        const params = global?.location?.search ? queryString.parse(global.location.search) : {};
        updateParams(params);

        window.addEventListener('popstate', updateParams);

        return () => window.removeEventListener('popstate', updateParams);
    }, [getParams, key, updateParams]);

    return queryParams;
}

export default useUrlQuery;
